.appnavbar{
height:100%;
width: 100%;
top: 0;
position: sticky;
background: hsla(216, 42%, 33%, 1);

    background: linear-gradient(0deg, hsla(216, 42%, 33%, 1) 19%, hsla(333, 54%, 51%, 1) 100%);
    
    background: -moz-linear-gradient(0deg, hsla(216, 42%, 33%, 1) 19%, hsla(333, 54%, 51%, 1) 100%);
    
    background: -webkit-linear-gradient(0deg, hsla(216, 42%, 33%, 1) 19%, hsla(333, 54%, 51%, 1) 100%);
    
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#314D78", endColorstr="#C63F7B", GradientType=1 );
z-index: 9999;
}

.appnavbar .brand img{
    display: inline;
    margin-right: 5px;

}
.appnavbar .brand img.logo-blue{
    opacity: 0;
    top: 15px;
    left: 15px;
}

.appnavbar .brand img.logo-white{
    opacity: 1;
}

.appnavbar.active{    
          
        background:rgba(122, 122, 133, 0.444);
}

.appnavbar.active .brand img.logo-blue
{
    opacity: 1;
}

.appnavbar.active .brand img.logo-white
{
    opacity: 0;
}
.btnit{
    margin:.7rem;
     padding: .7rem;
     border-radius:2rem;
     background-color:#312E2E;
     border-color:#489F8B;
     color:#489F8B;
  }
