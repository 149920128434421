.jumbotron
{
    background: hsla(216, 42%, 33%, 1);

    background: linear-gradient(0deg, hsla(216, 42%, 33%, 1) 19%, hsla(333, 54%, 51%, 1) 100%);
    
    background: -moz-linear-gradient(0deg, hsla(216, 42%, 33%, 1) 19%, hsla(333, 54%, 51%, 1) 100%);
    
    background: -webkit-linear-gradient(0deg, hsla(216, 42%, 33%, 1) 19%, hsla(333, 54%, 51%, 1) 100%);
    
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#314D78", endColorstr="#C63F7B", GradientType=1 );
  

}
