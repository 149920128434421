
.power{
    position: relative;
    margin-top: '102px';
    animation-name: example;
    animation-duration:4s;
    animation-iteration-count: infinite;
}

@keyframes example {
    0%   { left:0px; }
    25%  {left:300px; }
    50%  { left:400px;}
    75%  { left:500px;}
    100% { left:600px; }
  }